// src/App.tsx
import React, { FC, useState, useEffect } from "react";
import "./App.css";
import WalletConnect from "./WalletConnect";
import TradingPlansDashboard from "./TradingPlansDashboard";
import { getCookie } from "./utils"; // Import the getCookie function
import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import "@solana/wallet-adapter-react-ui/styles.css";

const App: FC = () => {
    const wallets = [new PhantomWalletAdapter()]; // Initialize Phantom Wallet Adapter

    // State to track connected wallet address
    const [walletAddress, setWalletAddress] = useState<string | null>(null);

    // State to track authentication status
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    // Check authentication status on mount
    useEffect(() => {
        const jwtToken = getCookie('CF_Authorization');
        if (jwtToken) {
            setIsAuthenticated(true);
            // const decodedToken = decodeJWT(jwtToken);
            // You can now use decodedToken.email or other user info
        } else {
            setIsAuthenticated(false);
        }
    }, []);


    // Listen for wallet connection changes via WalletConnect
    const handleWalletConnect = (address: string | null) => {
        setWalletAddress(address);
    };

    // Use a public RPC endpoint
    const endpoint = "https://rpc.ankr.com/solana"; // Public endpoint without API key

    if (!isAuthenticated) {
        return <p>Please log in to access the application.</p>;
    }

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div className="App">
                        <header className="App-header">
                            <h1>DigitalFx</h1>
                            {/* Pass down handler to track wallet address */}
                            <WalletConnect onWalletConnect={handleWalletConnect} />
                        </header>
                        <main>
                            {/* Pass connected wallet address to dashboard */}
                            <TradingPlansDashboard walletAddress={walletAddress} />
                        </main>
                    </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;
