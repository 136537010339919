import React, { useState, useEffect, useRef } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
    WalletModalProvider,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";
import { getCookie } from "./utils"; // Import getCookie function

interface WalletConnectProps {
    onWalletConnect: (address: string | null) => void; // Callback to notify parent of wallet connection state
}

const WalletConnect: React.FC<WalletConnectProps> = ({ onWalletConnect }) => {
    const { connection } = useConnection();
    const { publicKey, connected } = useWallet();
    const [solBalance, setSolBalance] = useState<number | null>(null);
    const [usdcBalance, setUsdcBalance] = useState<number | null>(null);

    const didFetchRef = useRef(false); // Track if fetch has already occurred

    useEffect(() => {
        document.title =
            process.env.REACT_APP_CF_PAGES_ENV === 'production'
                ? 'DigitalFx'
                : 'DigitalFx - TEST';
        console.log("ENV is ", process.env.REACT_APP_CF_PAGES_ENV);

        const backendUrl = process.env.REACT_APP_TP_BACK_URL;

        const fetchBalances = async () => {
            if (publicKey) {
                try {
                    const walletAddress = publicKey.toBase58();
                    // Get the JWT token from the cookie
                    const jwtToken = getCookie('CF_Authorization');
                    if (!jwtToken) {
                        console.error('Auth Error: JWT token not found');
                        return;
                    }

                    const response = await fetch(`${backendUrl}/api/solana/get-balances`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
                        },
                        body: JSON.stringify({ walletAddress }),
                    });

                    if (!response.ok) {
                        throw new Error('Error fetching balances');
                    }

                    const data = await response.json();
                    setSolBalance(data.solBalance);
                    setUsdcBalance(data.usdcBalance);
                } catch (error) {
                    console.error('Error fetching balances:', error);
                }
            } else {
                setSolBalance(null);
                setUsdcBalance(null);
            }
        };

        const saveUserProfile = async () => {
            if (publicKey) {
                const walletAddress = publicKey.toBase58();

                // Get the JWT token from the cookie
                const jwtToken = getCookie('CF_Authorization');
                if (!jwtToken) {
                    console.error('Auth Error: JWT token not found');
                    return;
                }

                try {
                    const response = await fetch(
                        `${backendUrl}/api/user-profile`,
                        {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
                            },
                            body: JSON.stringify({ wallet_address: walletAddress }),
                        }
                    );

                    const data = await response.json();
                    console.log("User profile saved/updated:", data);
                } catch (error) {
                    console.error("Error saving/updating user profile:", error);
                }
            }
        };

        if (connected && !didFetchRef.current) {
            didFetchRef.current = true;
            fetchBalances();
            saveUserProfile();
            onWalletConnect(publicKey?.toBase58() || null); // Notify parent of connected wallet
        } else if (!connected) {
            setSolBalance(null);
            setUsdcBalance(null);
            onWalletConnect(null); // Notify parent of wallet disconnection
            didFetchRef.current = false;
        }
    }, [publicKey, connected, connection, onWalletConnect]);

    return (
        <div>
            <WalletModalProvider>
                <WalletMultiButton />
            </WalletModalProvider>
            {connected && publicKey && (
                <div>
                    <p>Connected Wallet: {publicKey.toBase58()}</p>
                    <p>
                        SOL Balance:{" "}
                        {solBalance !== null ? `${solBalance.toFixed(4)} SOL` : "Fetching..."}
                    </p>
                    <p>
                        USDC Balance:{" "}
                        {usdcBalance !== null ? `${usdcBalance.toFixed(2)} USDC` : "Fetching..."}
                    </p>
                </div>
            )}
        </div>
    );
};

export default WalletConnect;
