import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Select, message } from "antd";
import {getCookie} from "./utils"; // Import the backend URL

const { Option } = Select;

interface TradingPlanFormProps {
    walletAddress: string | null; // Wallet address passed from parent component
    onPlanAdded: () => void; // Callback to refresh the dashboard
}

const TradingPlanForm: React.FC<TradingPlanFormProps> = ({ walletAddress, onPlanAdded }) => {
    const [name, setName] = useState("");
    const [investment, setInvestment] = useState(0);
    const [cap, setCap] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [tokens, setTokens] = useState("");
    const [timeHorizon, setTimeHorizon] = useState("");

    document.title =
        process.env.REACT_APP_CF_PAGES_ENV === 'production'
            ? 'DigitalFx'
            : 'DigitalFx - TEST';
    console.log("ENV is ", process.env.REACT_APP_CF_PAGES_ENV);

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    const handleSubmit = async () => {
        if (!walletAddress) {
            message.error("Please connect your wallet first.");
            return;
        }

        const plan = {
            wallet_address: walletAddress,
            name,
            investment_usdc: investment,
            cap_usdc: cap,
            withdraw_usdc: withdrawAmount,
            tokens,
            time_horizon: timeHorizon,
        };
        // Get the JWT token from the cookie
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        try {
            const response = await fetch(`${backendUrl}/api/trading-plans`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
                },
                body: JSON.stringify(plan),
            });

            if (!response.ok) {
                throw new Error("Failed to save trading plan");
            }

            const data = await response.json();
            console.log("Trading plan added:", data);
            message.success("Trading plan saved successfully!");
            onPlanAdded(); // Refresh the dashboard
        } catch (error) {
            console.error("Error saving trading plan:", error);
            message.error("Failed to save trading plan. Please try again.");
        }
    };

    return (
        <Form layout="vertical" onFinish={handleSubmit}>
            {/* Name Field */}
            <Form.Item label="Plan Name" required>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter a name for this trading plan"
                />
            </Form.Item>

            {/* Investment Field */}
            <Form.Item label="Investment (USDC)" required>
                <InputNumber
                    value={investment}
                    onChange={(value) => setInvestment(value || 0)}
                    min={0}
                    placeholder="Enter investment amount in USDC"
                />
            </Form.Item>

            {/* Cap Field */}
            <Form.Item label="Cap (USDC)" required>
                <InputNumber
                    value={cap}
                    onChange={(value) => setCap(value || 0)}
                    min={0}
                    placeholder="Enter cap amount in USDC"
                />
            </Form.Item>

            {/* Withdraw Amount Field */}
            <Form.Item label="Withdraw Amount (USDC)" required>
                <InputNumber
                    value={withdrawAmount}
                    onChange={(value) => setWithdrawAmount(value || 0)}
                    min={0}
                    placeholder="Enter withdraw amount in USDC"
                />
            </Form.Item>

            {/* Tokens Field */}
            <Form.Item label="Tokens (comma-separated)" required>
                <Input
                    value={tokens}
                    onChange={(e) => setTokens(e.target.value)}
                    placeholder="e.g., BTC,ETH,SOL"
                />
            </Form.Item>

            {/* Time Horizon Dropdown */}
            <Form.Item label="Time Horizon" required>
                <Select
                    onChange={(value) => setTimeHorizon(value)}
                    placeholder="Select time horizon"
                >
                    <Option value="1d">One Day</Option>
                    <Option value="1w">One Week</Option>
                    <Option value="1m">One Month</Option>
                    <Option value="1q">One Quarter</Option>
                    <Option value="1y">One Year</Option>
                </Select>
            </Form.Item>

            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form>
    );
};

export default TradingPlanForm;
