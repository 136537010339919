import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Modal, List, message } from "antd";
import TradingPlanForm from "./TradingPlanForm";
import { getCookie } from "./utils"; // Import getCookie function

interface TradingPlan {
    id: string;
    name: string;
    investment_usdc: number;
    cap_usdc: number;
    withdraw_usdc: number;
    tokens: string;
    time_horizon: string;
}

interface TradingPlansDashboardProps {
    walletAddress: string | null; // Wallet address passed from WalletConnect
}

const TradingPlansDashboard: React.FC<TradingPlansDashboardProps> = ({ walletAddress }) => {
    const [tradingPlans, setTradingPlans] = useState<TradingPlan[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    document.title =
        process.env.REACT_APP_CF_PAGES_ENV === 'production'
            ? 'DigitalFx'
            : 'DigitalFx - TEST';
    console.log("ENV is ", process.env.REACT_APP_CF_PAGES_ENV);

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;


    // Wrap fetchTradingPlans in useCallback
    const fetchTradingPlans = useCallback(() => {
        if (!walletAddress) return;

        // Get the JWT token from the cookie
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }


        fetch(`${backendUrl}/api/trading-plans`, {
            headers: {
                'Content-Type': 'application/json',
                'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch trading plans");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Fetched trading plans:", data);
                setTradingPlans(data);
            })
            .catch((error) => {
                console.error("Error fetching trading plans:", error);
                message.error("Could not load trading plans. Please try again.");
            });
    }, [walletAddress, backendUrl]);

    const addTradingPlan = () => {
        setIsModalOpen(false);
        fetchTradingPlans(); // Refresh the plans after adding
    };

    const deleteTradingPlan = (id: string) => {

        // Get the JWT token from the cookie
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        fetch(`${backendUrl}/api/trading-plans/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to delete trading plan");
                }
                message.success("Trading plan deleted successfully!");
                setTradingPlans((prevPlans) =>
                    prevPlans.filter((plan) => plan.id !== id)
                );
            })
            .catch((error) => {
                console.error("Error deleting trading plan:", error);
                message.error("Could not delete trading plan. Please try again.");
            });
    };

    useEffect(() => {
        fetchTradingPlans();
    }, [fetchTradingPlans]);

    if (!walletAddress) {
        return <p>Trading Plans Unavailable. Please connect your wallet.</p>;
    }

    return (
        <div>
            <h1>Trading Plans</h1>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Add Trading Plan
            </Button>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={tradingPlans}
                renderItem={(plan) => (
                    <List.Item>
                        <Card
                            title={plan.name}
                            extra={
                                <Button
                                    type="link"
                                    danger
                                    onClick={() => deleteTradingPlan(plan.id)}
                                >
                                    Delete
                                </Button>
                            }
                        >
                            <p>Investment: {plan.investment_usdc} USDC</p>
                            <p>Cap: {plan.cap_usdc} USDC</p>
                            <p>Withdraw Amount: {plan.withdraw_usdc} USDC</p>
                            <p>Tokens: {plan.tokens}</p>
                            <p>Time Horizon: {plan.time_horizon}</p>
                        </Card>
                    </List.Item>
                )}
            />
            <Modal
                title="Create Trading Plan"
                visible={isModalOpen}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
            >
                <TradingPlanForm
                    walletAddress={walletAddress}
                    onPlanAdded={addTradingPlan}
                />
            </Modal>
        </div>
    );
};

export default TradingPlansDashboard;
